import * as React from "react";
import { Link } from "gatsby";

import Layout from 'src/components/layout';


export default function IndexPage() {
    return (
        <Layout>

            <h1 className="mb-3">
                Hello from Katie!
            </h1>

            <p>
                Here you can find:
            </p>

            <ul className="my-3">

                <li><Link to="/cv/"> My CV</Link></li>

                <li>
                    <a href="https://github.com/ksdunne">My GitHub</a>
                </li>

                <li>
                    <a href="https://r.ksdunne.com">A website I built using R Markdown</a>{' '}
                    (<a href="https://github.com/KSDunne/RMarkdown1">source code</a>)
                </li>

                <li>
                    <a href="https://r.ksdunne.com">Examples of R code</a>
                </li>

            </ul>

        </Layout>
    );
}
